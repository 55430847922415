<template>
  <div class="w">
    <h1 class="titulo">
      <img class="margin" :src="getProductSrc(transaction.product)" height="50" alt="producto">
    </h1>
    <div v-if="disabled" class="contador"> 
      {{zero}} {{liters}}
    </div>
    <div v-else-if="showEights" class="contador fade"> 
      {{preparing}} {{liters}}
    </div>
    <div v-else class="contador">
      <number
        class="num"
        :from="0"
        :format="theFormat"
        :to="showLts"
        :duration="duration"
        :easing="curve" 
      /> 
      <div class="m-lts">
        {{liters}}
      </div>

    </div>

    <div v-if="!status || disabled">
      <p class="esperando">
        Surtidor en espera
      </p>
      <p class="inicio text-bg">espaciador</p>
      <p class="inicio final text-bg">espaciador</p>
    </div>
    <div v-else-if="isFinished">
      <p class="esperando">
        Despacho Finalizado
      </p>
      <p class="inicio">
        Inicio: {{printTimestamp(timestampStart)}}
      </p>
      <p class="inicio final" v-if="isFinished">
        Final: {{printTimestamp(timestampStop)}}
      </p>
      <p v-else class="inicio final text-bg">espaciador</p>
    </div>
    <div v-else>
      <p class="esperando">Despachando...</p>
      <p class="inicio">
        Inicio: {{printTimestamp(timestampStart)}}
      </p>
      <p class="inicio final text-bg">espaciador</p>
    </div>
  </div>
</template>

<script>
import { ANIMATION_DURATION, ANIMATION_CURVE, COUNTER_TOTAL_DIGITS, LITERS_SUFFIX, parseDate, parseNum, parseSpanish, RENDER_SPANISH } from "../../config"
export default {
  props: {
    transaction: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean
    },
    showEights: {
      type: Boolean
    }
  },

  data() {
    return {
      duration: ANIMATION_DURATION,
      curve: ANIMATION_CURVE,
      zero: RENDER_SPANISH ? "0,0".padStart(COUNTER_TOTAL_DIGITS, "0") : "0.0".padStart(COUNTER_TOTAL_DIGITS, "0"),
      preparing: RENDER_SPANISH ? "8,8".padStart(COUNTER_TOTAL_DIGITS, "8") : "8.8".padStart(COUNTER_TOTAL_DIGITS, "8"),
      liters: LITERS_SUFFIX
    }
  },

  computed: {
    showLts(){
      let p = this.transaction.quantity_lts ?? 0.0
      if (p == 0) return "0.0".padStart(COUNTER_TOTAL_DIGITS,"0")
      return p
    },
    status(){
      return this.transaction.status
    },
    isFinished(){
      return this.transaction.status == "finished"
    },
    hasProduct(){
      return this.transaction.product != undefined
    },
    timestampStart(){
      return this.transaction.timestamp_start ?? "--"
    },
    timestampStop(){
      return this.transaction.timestamp_stop ?? "--"
    }
  },
  methods: {
    printTimestamp(timestamp) {
      return parseDate(timestamp, false)
    },
    theFormat(number) {
      return RENDER_SPANISH ? parseSpanish(number) : parseNum(number)
    },
      getProductSrc(product) {
      switch (product) {
        case "Infinia Diesel":
          return require('../assets/images/infinia_diesel.png')
        case "Extravida":
        case "EXTRAVIDA":
        case "extravida":
          return require('../assets/images/extravida.png')
        case "Azul 32":
          return require('../assets/images/azul_32.png')
        case "V-Power Diesel":
        case "v-power-diesel":
          return require('../assets/images/v-power-diesel.png')
        case "V-Power Nafta":
        case "v-power-nafta":
          return require('../assets/images/v-power-nafta.png')
        default :
          return require('../assets/images/surtidor.svg')
      }
    },
  },

};
</script>
