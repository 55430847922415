<template>

  <div class="consola">

    <div class="cnt" v-if="!isWebinar">
      <h1 class="main-title">
        {{title}}
      </h1>
    </div>
    <div class="qr-container" v-if="!isWebinar">
      <div class="texto-qr">
        ¡Iniciá tu transacción! 
        <span class="subtitle-qr">
          Escanear QR o Ingresar token      
        </span>
      </div>
      <img :src="getQrSrc" width="240" alt="qr_code" class="qr-image">
    </div>
    <div class="qr-container-webinar" v-else>
      <h1 class="main-title-webinar">
        {{title}}
      </h1>
      <img :src="getQrSrc" width="350" alt="qr_code" class="qr-image-webinar">
    </div>

    <div class="counter-1">
      <Counter
        :transaction="transaction_1"
        :show-eights="preparing_1"
        :disabled="transaction_1.DISABLED"
      />
    </div>

  <div class="counter-2">
    <div class="table-title">Últimas Transacciones</div>
    <Table 
      :transactions="transactions" 
      :keys="transaction_keys"
    />
  </div>

    <div class="status">
      <Status
        :is-connected="isConnected"
        :online="online"
        :timestamp="timestamp"
        :time="time"
        :url="backend"
      />
    </div>
  </div>

</template>

<script>
import { GENERAL_TIMEOUT, WINDOW_TIMEOUT, TRANSACTION_TIMEOUT, ANIMATED_CLEANUP, TRANSACTION_KEYS, TABLE_ROWS, EIGHTS_ANIMATION_FULL_TIMER, ENV, IS_WEBINAR, TITLE } from '../../config';
import Counter from "../components/Counter";
import Status from "../components/Status";
import Table from "../components/Table";
const url =  ENV !== 'production' 
  ? 'ws://'+location.host.split(':')[0]+':'+process.env.VUE_APP_API_PORT
  : 'ws://'+location.host.split(':')[0];
  

export default {
  data: () => ({
    timer: 0,
    reload: 0,
    online: false,
    backend: url,
    transactions: [],
    transaction_keys: TRANSACTION_KEYS,
    preparing_1: false,
    transaction_1_timer: [],
    transaction_1: {},
    transaction_2_timer: [],
    transaction_2: {},
    time: null,
    timestamp: null,
    isConnected: false,
    isWebinar: IS_WEBINAR,
    title: TITLE,
  }),

  created() {
    this.transaction_1 = { dispenser_id: 1 , DISABLED: false};
    this.transaction_2 = { dispenser_id: 2 , DISABLED: false};
  },

  components: {
    Counter,
    Status,
    Table
  },

  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    updateStatusTransaction(data) {
      console.log('socket', data);
      this.timestamp = data.timestamp.split(' ')[1] ? undefined : data.timestamp.split('T')[1];
      this.timestamp = this.timestamp.split(".")[0]
      this.time = Date();
      this.online = true;
      clearTimeout(this.timer);
      clearTimeout(this.reload);
      //timer offline
      this.timer = setTimeout(() => {
        this.online = false
        this.greyCounters(this.online)
      },
        GENERAL_TIMEOUT
      );
      //timer f5
      this.reload = setTimeout(() => window.location.reload(),WINDOW_TIMEOUT);

      const id = data.dispenser_id;
      if (data.status == "finished") {
        //timer clear de transaccion completada
        data.isNew = true;
        this.transactions.unshift(data)

        if (this.transactions.length > TABLE_ROWS) this.transactions.pop()
        this[`transaction_${id}_timer`].push(setTimeout(() => {
          this.clearTransaction(id);
          this.clearTimers(id);
        },
        TRANSACTION_TIMEOUT));
      } else if (data.status == "started") {
        this[`preparing_${id}`] = true
        setTimeout(() => this.hideEights(id), EIGHTS_ANIMATION_FULL_TIMER)
        this.clearTimers(id);
      } else {
        this.clearTimers(id);
      }

      let isDisabled = false;
      switch(id){
        case 1:
          this.transaction_1 = data;
          this.transaction_1.DISABLED = isDisabled;
        break;
        case 2:
          this.transaction_2 = data;
          this.transaction_2.DISABLED = isDisabled;
        break;
      }
      
    },
  },

  computed: {
    getOnlineVariant() {
      if (this.online) {
        return 'primary';
      } else {
        return 'danger';
      }
    },
    getOnlineIcon() {
      if (this.online) {
        return 'wifi';
      } else {
        return 'wifi-off';
      }
    },
    getQrSrc() {
     // return require("../assets/images/qr_homebase.png");
      return ENV == "dev" || ENV == "development" ? require("../assets/images/qr_dev.png") : require("../assets/images/qr_prod.png")
    }
  },
  methods: {
    greyCounters(online) {
      if (online === false) {
        for (let i = 1; i < 3; i++) {
          this[`transaction_${i}`].DISABLED = true;
        }
      }
    },
    clearTransaction(dispenser_id) {
      console.log("limpieza de transa en surtidor", dispenser_id)
      this[`transaction_${dispenser_id}`] = { dispenser_id, DISABLED: !ANIMATED_CLEANUP };
    },
    hideEights(dispenser_id) {
      console.log("escondo los 8")
      this[`preparing_${dispenser_id}`] = !this[`preparing_${dispenser_id}`]
    },
    clearTimers(id) {
      this[`transaction_${id}_timer`].forEach(e => clearTimeout(e));
      this[`transaction_${id}_timer`] = []
    }
  },
}
</script>
