<template>
  <div>
    <div class="info">
      <div>
        <div v-if="isConnected && !online">
          <img width="25" src="../assets/images/loader.svg" alt=""> <span class="text-small">icon "ellipsis" from loading.io</span> 
        </div>
        Estado Conexión: <b>{{online ? "OK" : "OFFLINE"}}</b>
      </div> 
      <div v-if="!time">
        &nbsp;
      </div>
      <div v-else>
        <p v-if="isConnected && !online" class="mb-point-five">
          <b-icon icon="three-dots" class="text-bg" animation="cylon-vertical" font-scale="1"></b-icon>
        </p>
        <timeago :datetime="time" :auto-update="60" locale="es"></timeago> ({{this.timestamp}})
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        isConnected: Boolean,
        online: Boolean,
        timestamp: String,
        time: {
            default: null,
            type: String
        },
        url: String
    }
}
</script>