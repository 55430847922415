<template>
    <table class="table">
        <thead class="tb-head">
            <tr>
                <th :class="'text-capitalize '+classes(key)" v-for="(key, i) in keys" :key="i" >{{parseKey(key)}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(t,i) in transactions" :key="t.transaction_id" :class="'tb-row text-table' + (i == 0 || isNew(t) ? ' animated-row' : '')">
                <td v-for="(key, j) in keys" :key="j" :class="classes(key)">
                    {{parseVal(t, key)}}
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { ANIMATE_MULTIPLE_ROWS } from "../../config"
export default {
    props: {
        keys: {
            type: Array,
            default: () => []
        },
        transactions: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        recentTransactions() {
           let reversable =  this.transactions;
           return reversable.reverse()
        }
    },
    methods: {
        parseKey(key) {
            if (typeof key == "string") return key.replace(/_/g, ' ')
            return key.name
        },
        parseVal(val, key) {
            if (typeof key == "string") return val[key]
            if (key.parser) return key.parser(val[key.key])
            return val[key.key]
        },
        classes(key) {
            return (key.classes ?? []).join(" ")
        },
        isNew(t) {
            if (!ANIMATE_MULTIPLE_ROWS) return false
            setTimeout(() => {
                t.isNew = false;
            }, 12000);
            return t.isNew;
        }
    },
}
</script>