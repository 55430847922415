//===============================
// Parámetros Auxiliares
//===============================
const COUNTER_TOTAL_DIGITS = 5
const parseDate = (timestamp, hs = true) => {
    if (!timestamp) return ""
    const d = new Date(timestamp)
    if(isNaN(d.getTime())) return "-"

    const parsed = `${
      d.getDate().toString().padStart(2, '0')}/${
      (d.getMonth()+1).toString().padStart(2, '0')}/${
      d.getFullYear().toString().padStart(4, '0')} ${
      d.getHours().toString().padStart(2, '0')}:${
      d.getMinutes().toString().padStart(2, '0')}:${
      d.getSeconds().toString().padStart(2, '0')}`; 
    return hs ? parsed+"hs" : parsed
}


const parseNum = (p) => {
    if (p == 0) return "0.0".padStart(COUNTER_TOTAL_DIGITS,"0")
    let num = p.toString().match(/^-?\d+(?:\.\d{1})?/)[0]
    if (Number.isInteger(parseFloat(num))) {
        num = parseInt(num) + ".0"
    }
    return num.padStart(COUNTER_TOTAL_DIGITS,"0")
}

const parseSpanish = (p) => {
    let num = parseNum(p);
    return num.replace(".",",")
}
const LITERS_SUFFIX = "Lts."

/**
 * Indica si renderizar números en español
 */
const RENDER_SPANISH = true;

/**
 * Valor en segundos de la duración de la animación
 */
exports.ANIMATION_DURATION = 1.0 //en segundos
exports.NOTIFICATION_TIMEOUT = 60000; // miliseconds 1min
exports.GENERAL_TIMEOUT = 60000; // miliseconds 1min
exports.WINDOW_TIMEOUT = 1800000; // miliseconds 30min

exports.ENV = process.env.VUE_APP_QR_CODE ? process.env.VUE_APP_QR_CODE : "development";

/**
 * Intervalo en ms por el que se muestra una transacción
 * luego de finalizada
 */
exports.TRANSACTION_TIMEOUT = 10000 //miliseconds 10seconds

/**
 * Curva de animación de los números
 * @see https://codesandbox.io/s/8256nwlq78 Test de animaciones rápido
 * @see https://greensock.com/docs/v2/Easing Valores posibles
 */
exports.ANIMATION_CURVE = "Linear.easeNone" // linear

/**
 * Indica si animar la transicion de finalizado a en espera
 */
exports.ANIMATED_CLEANUP = false //en segundos

/**
 * Indica total de dígitos a renderizar en el contador
*/
exports.COUNTER_TOTAL_DIGITS = COUNTER_TOTAL_DIGITS


exports.parseDate = parseDate;
exports.parseNum = parseNum;
exports.parseSpanish = parseSpanish;
exports.RENDER_SPANISH = RENDER_SPANISH;


/**
 * Keys a mostrar en la tabla
 */
exports.TRANSACTION_KEYS = [
    {
        key: "transaction_id",
        name: "Transacción",
        classes: ["fw-bold", "fs-20", "text-capitalize"]
    },
    {
        key: "product",
        name: "Producto",
        classes: ["fw-bold", "fs-20"]
    },
    {
        key: "timestamp_start",
        name: "Fecha Inicio",
        parser: (t) => parseDate(t, false) 
    },
    {
        key: "quantity_lts",
        name: "Cantidad",
        classes: ["pe-1", "fw-bold", "fs-24", "th-center"],
        parser: (e) => (RENDER_SPANISH ? parseSpanish(e) : parseNum(e)) + " " + LITERS_SUFFIX
    },
]

/**
 * Cantidad de filas a mostrar en la tabla de transacciones recientes
 */
exports.TABLE_ROWS = 4

/**
 * Indica si animar múltiples filas de la tabla
 */
exports.ANIMATE_MULTIPLE_ROWS = false;


/**
 * Tiempo en milisegundos por los cuales se muestran los 888 animados
 */
exports.EIGHTS_ANIMATION_FULL_TIMER = 900


/**
 * Sufijo a mostrar en el contador
 */
exports.LITERS_SUFFIX = LITERS_SUFFIX

/**
 * Indica si está en modo webinar
 */
exports.IS_WEBINAR = true

/**
 * Titulo de la pagina (se muestra solo si `IS_WEBINAR = false`)
 */
exports.TITLE = `Escaneá y despachá`