import Vue from 'vue';
import App from './App.vue';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import JsonTree from 'vue-json-tree';
import './assets/css/custom.scss';
import './assets/css/estilos.scss';
import VueTimeago from 'vue-timeago';
import router from './router'
import VueNumber from 'vue-number-animation'
import { ENV } from '../config';

Vue.component('json-tree', JsonTree);

Vue.config.productionTip = false;
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
ENV !== 'production' 
  ? Vue.use(VueSocketIOExt, io('ws://'+location.host.split(':')[0]+':'+process.env.VUE_APP_API_PORT)) 
  : Vue.use(VueSocketIOExt, io('https://'+location.host.split(':')[0])) ;
Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'en',
  locales: {
    es: require('date-fns/locale/es')
  }
});
Vue.use(VueNumber)

new Vue({

  router,
  render: h => h(App)
}).$mount('#app');
